import "shared/extensions/date";

import { UpdateUserCommand, useUpdateUserFunctionRequest } from "shared/request/myHealthyAdvantageApi";

import { AppRoutes } from "core/AppRoutes";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { NoValueSelected } from "shared/constants/SelectConstants";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import PersonalInfoForm from "../../Components/PersonalInfoForm/PersonalInfoForm";
import { ResponsiveBreakpoints } from "shared/UI/ResponsiveBreakpoints";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useMatchMedia } from "shared/UI/Hooks/useMatchMedia";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "core/state/UserContext";

type Inputs = {
  forenames: string;
  surname: string;
  dateOfBirth: Date;
  gender: string;
  phoneNumber: string;
  divisionId: string | undefined;
};

export const PersonalDetails = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false);
  const { trigger } = useUpdateUserFunctionRequest({});
  const { user, saveUser } = useUserContext();
  const largerDisplay = useMatchMedia(ResponsiveBreakpoints.Desktop, false);

  const onSubmit = async (inputs: Inputs, reset: any, getValues: any) => {
    setErrorMessage(false);
    const fullData: UpdateUserCommand = {
      dateOfBirth: inputs.dateOfBirth.toDateOnly(),
      gender: inputs.gender,
      phoneNumber: inputs.phoneNumber,
      forenames: inputs?.forenames,
      surname: inputs?.surname,
      divisionId: inputs.divisionId === NoValueSelected ? undefined : inputs.divisionId,
    };

    try {
      const response = await trigger({ body: fullData });
      saveUser(response!.data);
      toastNotify(t("personalDetails.requestSuccessMessage"), "success");
    } catch {
      setErrorMessage(true);
    }
  };

  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={t("personalDetails.heading")} backRoute={largerDisplay ? AppRoutes.home : AppRoutes.account} />
      </CurvedTopBanner>
      <PersonalInfoForm user={user} onSubmit={onSubmit} errorMessage={errorMessage} type="personalDetails" />
    </>
  );
};
