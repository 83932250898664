import { DatoContent } from "../DatoContent/DatoContent";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { useGetDomainLocale } from "../../../../../shared/src/core/hooks/useGetDomainLocale";
import { useGetPublicPageFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useTranslation } from "react-i18next";

type DatoContentPageProps = {
  slug: string | undefined;
  localeId?: string;
};
export const DatoContentPage = ({ slug, localeId }: DatoContentPageProps) => {
  const { t } = useTranslation();
  const domainLocaleId = useGetDomainLocale(window.location.hostname);

  const { data, isLoading, error, mutate } = useGetPublicPageFunctionRequest(
    {
      slug: slug,
      localeId: localeId ?? domainLocaleId,
    },
    { needsBearerToken: false, revalidateOnMountIfStale: false, showErrorIfStale: false }
  );

  return (
    <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("datoContentPage.error")} onRetry={mutate} isFullScreenView={true}>
      <div className="break-words">
        <DatoContent content={data?.content} />
      </div>
    </ViewStateWrapper>
  );
};
