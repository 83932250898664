import { BottomNavBar } from "./Components/BottomNavBar";
import { Outlet } from "react-router-dom";
import { TopNavBar } from "./Components/TopNavBar";
import { AppVersionModal } from "UIPalette/AppVersionModal/AppVersionModal";

export const AppLayout = () => {
  return (
    <div className="flex flex-col min-h-screen gap-0 overflow-clip">
      <TopNavBar />
      <main role="main">
        <div className="flex flex-col w-full mx-auto lg:max-w-[1761px] mb-6 px-6 md:px-[5rem]">
          <AppVersionModal />
          <Outlet />
        </div>
      </main>
      <BottomNavBar />
    </div>
  );
};
