import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { LeaderboardsList } from "../../../HealthHub/components/LeaderboardsList/LeaderboardsList";
import { Modal } from "shared/UI/Modal/Modal";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import StylableButton from "shared/UI/Buttons/StylableButton";
import classNames from "classnames";
import { t } from "i18next";
import { useGetUserLeaderboardsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { Input } from "shared/UI/Inputs/Input/Input";

export const LeaderboardsHome = () => {
  const navigate = useNavigate();
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const [existingLeaderboardCode, setExistingLeaderboardCode] = useState("");
  const { data: userLeaderboards, isLoading, error, mutate } = useGetUserLeaderboardsFunctionRequest({});
  const hasActiveLeaderboards = !isLoading && !error && userLeaderboards?.active && userLeaderboards!.active.length > 0;
  const hasCompletedLeaderboards = !isLoading && !error && userLeaderboards?.completed && userLeaderboards!.completed.length > 0;

  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={t("leaderboards.home.heading")} backRoute={-1} />
      </CurvedTopBanner>
      <p role="note" className="col-start-1 sm:col-span-2">
        {t("leaderboards.home.description")}
      </p>
      <div className="flex flex-row mt-12 gap-4 justify-between">
        <StylableButton
          fullWidth
          text={t("leaderboards.home.createButton")}
          color="primary"
          className="w-full [&>button]:py-[14px]"
          onClick={() => navigate("create")}
        />
        <StylableButton
          fullWidth
          text={t("leaderboards.home.joinButton")}
          color="primary"
          className="w-full [&>button]:py-[14px]"
          onClick={() => setJoinModalOpen(true)}
        />
      </div>
      <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("leaderboards.errors.load")} onRetry={mutate} isFullScreenView={true}>
        {hasActiveLeaderboards && (
          <div className="col-start-1 my-6">
            <LeaderboardsList leaderboards={userLeaderboards?.active} title={t("leaderboards.home.activeTitle")} />
          </div>
        )}
        {hasCompletedLeaderboards && (
          <div className={classNames("my-6", { "col-start-1": !hasActiveLeaderboards })}>
            <LeaderboardsList leaderboards={userLeaderboards?.completed} title={t("leaderboards.home.completedTitle")} />
          </div>
        )}
        <Hideable hidden={!joinModalOpen}>
          <Modal title={t("leaderboards.joinModal.title")} headerSize="2xl" width="base" close={() => setJoinModalOpen(false)}>
            <div className="p-3">
              <Input
                label={t("leaderboards.joinModal.enterCode")}
                type="text"
                onChange={(val) => setExistingLeaderboardCode(val.currentTarget.value)}
                value={existingLeaderboardCode}
              />
              <div className="flex flex-row justify-between pt-4">
                <StylableButton
                  text={t("leaderboards.joinModal.back")}
                  color="primary"
                  outlineButton
                  onClick={() => setJoinModalOpen(false)}
                ></StylableButton>
                <StylableButton
                  text={t("leaderboards.joinModal.preview")}
                  color="primary"
                  onClick={() => navigate(`preview/${existingLeaderboardCode}`)}
                />
              </div>
            </div>
          </Modal>
        </Hideable>
      </ViewStateWrapper>
    </>
  );
};
