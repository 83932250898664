import "shared/extensions/date";

import { Controller, useForm } from "react-hook-form";

import { DivisionSelect } from "UIPalette/Components/DivisionSelect/DivisionSelect";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { GenderSelect } from "UIPalette/Components/GenderSelect/GenderSelect";
import { NoValueSelected } from "shared/constants/SelectConstants";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { ValidatableDatePicker } from "shared/UI/Inputs/DateInput/ValidatableDatePicker";
import { ValidatableInput } from "shared/UI/Inputs/Input/ValidatableInput";
import { ValidatableWrapper } from "shared/UI/Inputs/ValidatableWrapper";
import { phoneNumberRegex } from "shared/utils/regex";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type Inputs = {
  forenames: string;
  surname: string;
  dateOfBirth: Date;
  gender: string;
  phoneNumber: string;
  divisionId: string | undefined;
};

const MIN_AGE = 16;

type PersonalInfoFormProps = {
  user: any;
  onSubmit: any;
  errorMessage: any;
  type: "additionalInfo" | "personalDetails";
};

export default function PersonalInfoForm({ user, onSubmit, errorMessage, type }: PersonalInfoFormProps) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    control,
    formState: { errors, defaultValues, isSubmitting, isDirty, isSubmitSuccessful },
  } = useForm<Inputs>({
    defaultValues:
      type === "additionalInfo"
        ? {
            forenames: user?.forenames ?? "",
            surname: user?.surname ?? "",
            dateOfBirth: Date.fromDateOnly(user?.dateOfBirth),
            gender: user?.gender ?? "",
            phoneNumber: user?.phoneNumber ?? "",
            divisionId: user?.division?.id,
          }
        : {
            forenames: user?.forenames ?? "",
            surname: user?.surname ?? "",
            dateOfBirth: user?.dateOfBirth ? Date.fromDateOnly(user.dateOfBirth) : undefined,
            gender: user?.gender ?? "",
            phoneNumber: user?.phoneNumber ?? "",
            divisionId: user?.division?.id ?? NoValueSelected,
          },
  });

  const isMinimumAge = (inputDate: Date) => {
    const today = new Date();
    const birth = new Date(inputDate);
    const targetDate = new Date(birth.setFullYear(birth.getFullYear() + MIN_AGE));

    return today > targetDate || t(`${type}.dateOfBirthMinAgeErrorMessage`, { minAge: MIN_AGE });
  };

  const dobValidationRules = {
    required: t(`${type}.dateOfBirthRequiredErrorMessage`),
    validate: {
      isMinimumAge,
    },
  };

  useEffect(() => {
    reset(getValues());
  }, [reset, getValues, isSubmitSuccessful]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ValidatableInput
          label={t(`${type}.forenames`)}
          type="text"
          defaultValue={defaultValues?.forenames}
          validationState={errors.forenames ? "error" : undefined}
          validationMessage={t(`${type}.forenamesErrorMessage`)}
          {...register("forenames", { required: true })}
        />

        <ValidatableInput
          type="text"
          label={t(`${type}.surname`)}
          defaultValue={defaultValues?.surname}
          validationState={errors.surname ? "error" : undefined}
          validationMessage={t(`${type}.surnameErrorMessage`)}
          {...register("surname", { required: true })}
        />

        <Controller
          name={"dateOfBirth"}
          control={control}
          rules={dobValidationRules}
          render={({ field: { onChange, value } }) => {
            return (
              <ValidatableDatePicker
                maxDate={new Date()}
                label={t(`${type}.dateOfBirth`)}
                date={value}
                validationState={errors.dateOfBirth ? "error" : undefined}
                validationMessage={errors.dateOfBirth?.message}
                setDate={onChange}
              />
            );
          }}
        />

        <Controller
          name={"gender"}
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <ValidatableWrapper validationMessage={t(`${type}.genderErrorMessage`)} validationState={errors.gender ? "error" : undefined}>
              <GenderSelect onChange={onChange} label={t(`${type}.gender`)} value={value} />
            </ValidatableWrapper>
          )}
        />

        <ValidatableInput
          validationMessage={t(`${type}.phoneNumberErrorMessage`)}
          validationState={errors.phoneNumber ? "error" : undefined}
          type="tel"
          defaultValue={defaultValues?.phoneNumber}
          label={t(`${type}.phoneNumber`)}
          {...register("phoneNumber", { pattern: phoneNumberRegex })}
        />

        <Controller
          name={"divisionId"}
          control={control}
          render={({ field: { onChange, value } }) => <DivisionSelect onChange={onChange} label={t(`${type}.division`)} value={value} />}
        />

        <StylableButton
          className="mt-4 bright-button"
          fullWidth
          disabled={!isDirty || isSubmitting}
          color="primary"
          type="submit"
          text={t(`${type}.submitForm`)}
          aria-label={t(`${type}.submitForm`)}
        />
      </form>

      {errorMessage && (
        <div className="mt-2">
          <ErrorAlert content={t(`${type}.requestErrorMessage`)} />
        </div>
      )}
    </>
  );
}
